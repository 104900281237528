.qr-code-2 {
    @media @md {

        margin-left  : -15px;
        margin-right : -15px;

        > div {
            float         : left;
            width         : 50%;
            padding-left  : 15px;
            padding-right : 15px;
        }

        &.style-2 {
            > div {
                float : right;
            }
        }
    }
}