.tabs {
    .tabs-nav {
        font-size     : 0;
        border-bottom : 1px solid @gray-border;
        > ul {
            .clearfix();
            display : inline-block;

            li {
                float            : left;
                border           : 1px solid @gray-border;
                margin-bottom    : -1px;
                background-color : @light-base;
                .easing-animation();

                &:before {
                    .easing-animation();
                    content          : "";
                    display          : block;
                    width            : 100%;
                    height           : 1px;
                    background-color : transparent;
                }

                &:not(:last-of-type) {
                    margin-right : -1px;
                }

                > a {
                    display : inline-block;
                    padding : 8px 20px;
                    color   : @gray-dark;
                }

                &:not(.ui-tabs-active):hover {
                    background-color : #F6F6F6;
                }

                &.ui-tabs-active {
                    border-bottom-color : @light-base;
                    border-top-color    : @brand-primary;
                    &:before {
                        background-color : @brand-primary;
                    }
                }
            }
        }
    }

    .tabs-content {
        border           : 1px solid @gray-border;
        border-top       : 0;
        background-color : @light-base;
    }

    &.full {
        .tabs-nav {
            > ul {
                li {
                    background-color : @gray-lighter;

                    > a {
                        padding : 8px 25px;
                    }

                    &:not(.ui-tabs-active):hover {
                        background-color : @light-base;
                    }

                    &.ui-tabs-active {
                        background-color : @light-base;
                    }
                }
            }
        }
    }

    &.right {
        .tabs-nav {
            text-align : right;
        }
    }

    @media @xs-max {
        .tabs-nav {
            > ul {
                width : 100%;

                li {
                    text-align : center;
                    float      : none;
                    display    : block;

                    &:not(:last-of-type) {
                        margin-right : 0;
                    }
                }
            }
        }
    }
}

.product-detail-tabs {
    .__item.__default {
        padding : 35px 40px;

        p {
            letter-spacing : 0.02em;

            &:last-of-type {
                margin-bottom : 0;
            }
        }

    }

    .__item.__reviews {
        .__head {
            padding-top   : 30px;
            padding-right : 20px;
            padding-left  : 20px;

            p {
                float         : left;
                line-height   : 30px;
                margin-bottom : 0;
            }

            .star-ratings {
                float : right;
            }

            hr {
                float         : left;
                width         : 100%;
                margin-bottom : 0;
            }
        }

        .__body {
            .__comment-list {
                padding: 0 20px;
                .__comment {
                    border-bottom : 1px solid @gray-lighter;
                    padding: 20px 0 60px;

                    .__content-left {
                        float         : left;
                        padding-right : 20px;
                        padding-top   : 10px;

                        img {
                            width : 100px;
                        }
                    }

                    .__content-right {
                        padding-left: 120px;
                        .__info {
                            margin-bottom : 15px;
                            float: left;
                            width: 100%;

                            > span {
                                display     : block;
                                float       : left;
                                .font-heading;
                                line-height : 30px;
                            }

                            .__divide {
                                height           : 30px;
                                width            : 1px;
                                margin-right : 10px;
                                margin-left : 10px;

                                span {
                                    display: block;
                                    height: 16px;
                                    margin-top: 7px;
                                    background-color : @gray-border;
                                }
                            }

                            .star-ratings {
                                float: right;
                            }
                        }

                        p {
                            float: left;
                            margin-bottom : 0;
                        }
                    }
                }
            }

            .__review-form {
                padding-top    : 40px;
                padding-left   : 30px;
                padding-right  : 30px;
                padding-bottom : 30px;

                h6 {
                    margin-bottom : 35px;
                }

                .__inputs {
                    .clearfix();
                    margin-right : -15px;
                    margin-left  : -15px;

                    span {
                        display       : block;
                        width         : 100%;
                        padding-right : 15px;
                        padding-left  : 15px;
                    }
                }

                .___message {
                    textarea {
                        height : 150px;
                    }
                }

                .__rating {
                    float          : left;
                    padding-top    : 10px;
                    padding-bottom : 10px;
                    margin-bottom  : 30px;

                    span {
                        float        : left;
                        line-height  : 30px;
                        margin-right : 20px;
                    }

                    i {
                        float     : left;
                        display   : block;
                        font-size : 18px;
                        color     : @gray-light;
                        &:before {
                            line-height : 30px;
                            display     : block;
                        }
                    }
                }
                .__button {
                    float : right;

                    .btn {
                        padding-top : 17px;
                        line-height : 16px;
                    }
                }
            }
        }
    }

    @media @sm {
        .__item.__reviews {
            .__body {
                .__review-form {
                    .__inputs {
                        span {
                            float : left;

                            &:not(:last-of-type) {
                                width : 50%;
                            }
                        }
                    }
                }
            }
        }
    }

    @media @md {
        .__item.__reviews {
            .__body {
                .__review-form {
                    .__inputs {
                        span {
                            &:not(:last-of-type) {
                                width : 100% / 3;
                            }
                            width : 100% / 3;
                        }
                    }
                }
            }
        }
    }

    @media @xs-max {
        .__item.__reviews {
            .__body {

                .__comment-list {
                    .__comment {
                        padding: 30px 0;
                        .__content-left {
                            padding-right : 0px;
                            padding-top : 5px;

                            img {
                                width : 80px;
                            }
                        }

                        .__content-right {
                            padding-left: 0;
                            .__info {
                                padding-left: 100px;
                                float: none;

                                > span {
                                    width: 100%;
                                }

                                .__divide {
                                    display: none;
                                }

                                .star-ratings {
                                    float : left;
                                    width : 100%;
                                }
                            }

                            p {
                                float: none;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width : @screen-xs) {
        .__item.__default {
            padding : 35px 15px;
        }

        .__item.__reviews {
            .__head {
                .star-ratings {
                    float : left;
                }
            }

            .__body {
                .__review-form {
                    padding-left  : 15px;
                    padding-right : 15px;

                    .__rating {
                        width : 100%;
                    }

                    .__button {
                        width      : 100%;
                        text-align : center;
                    }
                }
            }
        }
    }
}