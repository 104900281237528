@dark-footer-content-color: #BABABA;

footer.page-footer {
    position : relative;
    overflow : hidden;
    .clearfix();
    //
    //.bgc-gray-base, .bgc-gray-darkest, .bgc-gray-darker {
    //    .btn.btn-primary:hover {
    //        background-color : @light-base;
    //        color: @brand-primary;
    //    }
    //}
}

// Footer Foot
//===================

//Footer Foot 1
.footer-foot-1 {
    border-bottom  : 1px solid;
    border-top     : 1px solid;
    border-color   : transparent;
    padding-top    : 20px;
    padding-bottom : 20px;

    p {
        margin-bottom : 0;
        line-height   : 30px;
    }

    &.bgc-light {
        color        : @gray-dark;
        border-color : @gray-border;
    }

    &.bgc-gray-base, &.bgc-gray-darkest, &.bgc-gray-darker {
        color : fade(@light-base, 70%);
    }
}

//Footer Foot 2
.footer-foot-2 {
    border-bottom  : 1px solid;
    border-top     : 1px solid;
    border-color   : transparent;
    padding-top    : 20px;
    padding-bottom : 20px;

    ul.social {
        font-size : 0;
        li {
            margin : 0 8px;

            &:last-child {
                margin-right : 8px;
            }

            &:first-child {
                margin-left : 8px;
            }

            a {
                font-size   : 18px;
                height      : 30px;
                line-height : 30px;
                //display     : inline-block;

                i {
                    line-height : 30px;
                }
            }
        }
    }

    &.bgc-light {
        color        : @gray-dark;
        border-color : @gray-border;
    }

    &.bgc-gray-base, &.bgc-gray-darkest, &.bgc-gray-darker {
        color : @dark-footer-content-color;
    }
}

//Footer Foot 3
.footer-foot-3 {
    border-bottom  : 1px solid;
    border-top     : 1px solid;
    border-color   : transparent;
    padding-top    : 20px;
    padding-bottom : 10px;

    .__content-left, .__content-right {
        padding-left  : 15px;
        padding-right : 15px;
    }

    .__content-left {
        p {
            line-height   : 30px;
            margin-bottom : 15px;
        }
    }

    .__content-right {
        nav.footer-nav {
            ul {
                font-size : 0;
                li {
                    display      : inline-block;
                    margin-left  : 15px;
                    margin-right : 15px;
                    a {
                        line-height : 30px;
                        font-size   : @font-size-h6-s;
                    }
                }
            }
        }
    }

    &.bgc-light {
        color        : @gray-dark;
        border-color : @gray-border;
    }

    @media @lg {
        .__content-left {
            float : left;
            p {
                margin-bottom : 0;
            }
        }

        .__content-right {
            float : right;
            nav.footer-nav {
                ul {
                    li {
                        float        : left;
                        margin-left  : 20px;
                        margin-right : 20px;

                        &:first-of-type {
                            margin-left : 0;
                        }

                        &:last-of-type {
                            margin-right : 0;
                        }
                    }
                }
            }
        }
    }

    @media @md-max {
        text-align : center;
    }
}

//Footer Foot 4
.footer-foot-4 {
    border-bottom  : 1px solid;
    border-top     : 1px solid;
    border-color   : transparent;
    padding-top    : 20px;
    padding-bottom : 20px;

    .__content-left, .__content-right {
        padding-left  : 15px;
        padding-right : 15px;
    }

    .__content-left {
        p {
            line-height   : 30px;
            margin-bottom : 15px;
        }
    }

    .__content-right {
        ul.social {
            font-size : 0;
            li {
                margin : 0 6px;
                a {
                    background-color : #BBBBBB;
                    font-size        : @font-size-h6;

                    &:hover {
                        background-color : @brand-primary;
                    }
                }
            }
        }
    }

    &.bgc-light {
        border-color : @gray-border;
        .__content-left {
            color : @gray-dark;
        }

        .__content-right {
            ul.social {
                li {
                    a {
                        color : @light-base;
                    }
                }
            }
        }
    }

    &.bgc-gray-base, &.bgc-gray-darkest, &.bgc-gray-darker {
        .__content-left {
            color : @light-base;
        }
    }

    &.bgc-gray-base {
        .__content-right {
            ul.social {
                li {
                    a {
                        color : @gray-base;
                    }
                }
            }
        }
    }

    &.bgc-gray-darkest {
        .__content-right {
            ul.social {
                li {
                    a {
                        color : @gray-darkest;
                    }
                }
            }
        }
    }

    &.bgc-gray-darker {
        .__content-right {
            ul.social {
                li {
                    a {
                        color : @gray-darker;
                    }
                }
            }
        }
    }

    @media @md {
        .__content-left {
            float : left;
            p {
                margin-bottom : 0;
            }
        }

        .__content-right {
            float : right;
            ul {
                li {

                    &:first-of-type {
                        margin-left : 0;
                    }

                    &:last-of-type {
                        margin-right : 0;
                    }
                }
            }
        }
    }

    @media @sm-max {
        text-align : center;
    }
}

//Widget
//===================

//logo
.footer-widget-logo {
    position : relative;

    p {
        margin-bottom  : 0;
        letter-spacing : 0.1em;
        line-height    : 30px;

        i.icon {
            position  : relative;
            top       : -2px;
            font-size : 24px;
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        hr {
            border-color : @gray-darker + #111111;
        }
    }
}

//About
.footer-widget-about {
    ul.social {
        font-size : 0;
        li {
            margin : 0 6px;
            a {
                background-color : @gray-light;
                font-size        : @font-size-h6;

            }

            &:first-of-type {
                margin-left : 0;
            }

            &:last-of-type {
                margin-right : 0;
            }
        }
    }

    .bgc-light & {
        ul.social li a {
            &:hover {
                background-color : @brand-primary;
            }
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        p {
            color : @dark-footer-content-color;
        }

        ul.social li a {
            &:hover {
                background-color : @light-base;
            }
        }
    }

    .bgc-gray-base & {
        ul.social li a {
            color : @gray-base;
        }
    }

    .bgc-gray-darkest & {
        ul.social li a {
            color : @gray-darkest;
        }
    }

    .bgc-gray-darker & {
        ul.social li a {
            color : @gray-darker;
        }
    }
}

//Recent Posts
.footer-widget-recent-post {
    .__content {
        span {
            display       : block;
            margin-bottom : 5px;

            > a {
                line-height : 30px;
            }
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        .__content {
            color : @dark-footer-content-color;
        }
    }
}

// Information
.footer-widget-info {
    .__content {
        .clearfix();

        > div {
            float : left;
            width : 100%;

            > span {
                display       : block;
                margin-bottom : 5px;

                > a {
                    line-height : 30px;
                    &:before {
                        content      : "\e169";
                        font-family  : "megatron";
                        font-size    : 9px;
                        color        : @brand-primary;
                        margin-right : 10px;
                    }
                }
            }
        }

        &.__2-col {
            > div {
                width : 50%;
            }
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        .__content {
            color : @dark-footer-content-color;
        }
    }
}

// Contact
.footer-widget-contact {
    .__content {
        > div {
            margin-bottom : 15px;

            i {
                color        : @brand-primary;
                font-size    : 18px;
                height       : 18px;
                margin-right : 5px;
            }

            span {
                line-height    : 30px;
                vertical-align : middle;
            }
        }
    }
}

//Form
.footer-widget-form {
    form {
        .__inputs {
            margin-left  : -15px;
            margin-right : -15px;

            > span {
                display       : block;
                padding-left  : 15px;
                padding-right : 15px;
                input {
                    background-color : transparent;
                }
            }
        }

        .__message {
            textarea {
                background-color : transparent;
            }
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        form {
            .__inputs {
                margin-left  : -15px;
                margin-right : -15px;

                > span {
                    display       : block;
                    padding-left  : 15px;
                    padding-right : 15px;
                    input {
                        background-color : transparent;
                        border-color     : @gray-darker + #111111;
                        &:focus {
                            border-color : @brand-primary;
                        }
                    }
                }
            }

            .__message {
                textarea {
                    border-color : @gray-darker + #111111;
                    &:focus {
                        border-color : @brand-primary;
                    }
                }
            }
        }
    }
}

//Tag
.footer-widget-tag {
    .__content {
        font-size    : 0;
        margin-right : -8px;

        > a {
            font-size     : @font-size-h6-s;
            display       : inline-block;
            line-height   : 30px;
            padding       : 4px 15px;
            border        : 1px solid @gray-border;
            margin-right  : 8px;
            margin-bottom : 8px;

            &:hover {
                border-color : @brand-primary;
            }
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        .__content {
            > a {
                color        : @dark-footer-content-color;
                border-color : @gray-darker + #111111;

                &:hover {
                    color        : @brand-primary;
                    border-color : @brand-primary;
                }
            }
        }
    }
}

// News letter
.footer-widget-newsletter {
    p {
        margin-bottom : 15px;
    }

    form {
        .__inputs {
            input {
                margin-bottom    : 25px;
                background-color : transparent;
            }
        }

        .__button {
            margin-left  : auto;
            margin-right : auto;
            max-width    : 270px;
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        p {
            color : @dark-footer-content-color;
        }

        form {
            .__inputs {
                input {
                    background-color : transparent;
                    border-color     : @gray-darker + #111111;
                    &:focus {
                        border-color : @brand-primary;
                    }
                }
            }
        }
    }
}

//journal
.footer-widget-journal {
    .__content {
        .__inputs {
            margin-bottom : 28px;

            input {
                background-color : transparent;
                border           : 0px;
                border-bottom    : 1px solid;
                padding          : 5px 0;
                letter-spacing   : 0.05em;
                .font-content;
                margin-bottom    : 8px;

                &:focus {
                    border-color : @brand-primary !important;
                }
            }
        }

        .__button {
            .btn-border {
                &:hover, &:focus {
                    border-color     : @brand-primary !important;
                    color            : @light-base !important;
                    background-color : @brand-primary !important;
                }
            }
        }
    }

    .bgc-light & {
        .__content {
            .__inputs {
                input {
                    &::-webkit-input-placeholder {
                        color : @gray-darker;
                    }

                    &:-moz-placeholder { /* Firefox 18- */
                        color : @gray-darker;
                    }

                    &::-moz-placeholder { /* Firefox 19+ */
                        color : @gray-darker;
                    }

                    &:-ms-input-placeholder {
                        color : @gray-darker;
                    }

                    border-color : @gray-border;
                }
            }

            .__button {
                .btn-border {
                    border-color : @gray-border;
                    color        : @gray-darker;
                }
            }
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        .__content {
            .__inputs {
                input {
                    &::-webkit-input-placeholder {
                        color : @dark-footer-content-color;
                    }

                    &:-moz-placeholder { /* Firefox 18- */
                        color : @dark-footer-content-color;
                    }

                    &::-moz-placeholder { /* Firefox 19+ */
                        color : @dark-footer-content-color;
                    }

                    &:-ms-input-placeholder {
                        color : @dark-footer-content-color;
                    }

                    color        : @dark-footer-content-color;
                    border-color : @gray-darker + #111111;
                }
            }

            .__button {
                .btn-border {
                    border-color : @gray;
                    color        : #AAAAAA;
                }
            }
        }
    }
}

// Social
.footer-widget-social {
    .__content {
        > div {
            line-height   : 1;
            margin-bottom : 5px;

            > a {
                display : inline-block;

                i {
                    margin-right : 15px;
                    font-size    : 18px;
                    height       : 18px;
                }

                span {
                    line-height    : 30px;
                    vertical-align : middle;
                }
            }
        }
    }
    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        .__content {
            color : @dark-footer-content-color;
        }
    }
}

// Product
.footer-widget-shop-product {
    .block-shop-product-small-2 {
        &:not(:last-of-type) {
            border-bottom : 1px solid;
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        .block-shop-product-small-2 {
            border-color : @gray-darker + #111111;

            .__price {
                del {
                    color : @gray;
                }

                span {
                    color : #AAAAAA;
                }
            }
        }
    }
}

.footer-widget-shop-category {
    &:hover {
        .__title {
            i {
                color : @brand-primary;
            }
        }
    }

    .__title {
        margin-bottom : 15px;

        h6 {
            margin-bottom : 5px;
        }

        i {
            font-size : 20px;
            color     : fade(@dark-footer-content-color, 90%);
        }
    }

    .__content {
        > div {
            &:not(:last-of-type) {
                margin-bottom : 10px;
            }

            a {
                line-height   : 32px;
                padding-left  : 15px;
                padding-right : 15px;
                display       : inline-block;
                border        : 1px solid transparent;

                &:hover {
                    border-color : @brand-primary;
                }
            }
        }
    }

    .bgc-light & {
        .__content {
            > div {
                a {
                    color : @gray-darker;
                }
            }
        }
    }

    .bgc-gray-base &, .bgc-gray-darkest &, .bgc-gray-darker & {
        .__content {
            > div {
                a {
                    color : @dark-footer-content-color;
                }
            }
        }
    }
}

// Footer preset
//===================
.footer-preset-1 {
    .footer-body {
        .footer-widget-logo {
            padding-top    : 100px;
            padding-bottom : 90px;
            .megatron {
                margin-bottom : 15px;
                .logo {
                    .square(100px);
                }
            }
        }
    }
}

.footer-preset-2 {
    .footer-body {
        padding-top    : 80px;
        padding-bottom : 45px;

        .footer-widget-about {
            margin-bottom : 40px;

            p {
                margin-bottom : 40px;
            }
        }

        .footer-widget-recent-post {
            margin-bottom : 40px;
        }

        .footer-widget-gallery {
            h4 {
                margin-bottom : 35px;
            }

            max-width : 500px;
        }
    }

    @media @xs-max and (min-width : 600px) {
        .__block-wrapper-1 {
            width : 7 / 12 * 100%;
        }

        .__block-wrapper-2 {
            width : 5 / 12 * 100%;
        }
    }
}

.footer-preset-3 {
    .footer-body {
        padding-top    : 70px;
        padding-bottom : 30px;

        .footer-widget-about {
            margin-bottom : 40px;

            .megatron {
                margin-bottom : 35px;
            }

            p {
                margin-bottom : 40px;
            }
        }

        .footer-widget-info {
            padding-top   : 15px;
            margin-bottom : 25px;

            h4 {
                margin-bottom : 45px;
            }
        }

        .footer-widget-contact {
            padding-top   : 15px;
            margin-bottom : 40px;

            h4 {
                margin-bottom : 45px;
            }
        }
    }

    @media (max-width : @screen-xs) {
        .footer-widget-info {
            .__content {
                > div {
                    width : 100%;
                }
            }
        }
    }
}

.footer-preset-4 {
    .footer-body {
        padding-top    : 130px;
        padding-bottom : 110px;

        .footer-widget-about {
            margin-bottom : 40px;

            .megatron {
                margin-bottom : 35px;
            }

            p {
                margin-bottom : 40px;
            }
        }
    }

    @media @sm {
        form {
            .__inputs {

                > span {
                    float : left;
                }

                .__name, .__phone {
                    width : 50%;
                }

                .__email {
                    width : 100%;
                }
            }

            .__message {
                textarea {
                    background-color : transparent;
                }
            }
        }
    }

    @media @md-max {
        .footer-body {
            padding-top    : 80px;
            padding-bottom : 80px;
        }
    }
}

.footer-preset-5 {
    .footer-body {
        padding-top    : 80px;
        padding-bottom : 70px;

        .footer-widget-about {
            margin-bottom : 25px;

            .megatron {
                margin-bottom : 25px;
            }
        }

        .footer-widget-gallery {
            margin-bottom : 25px;
            padding-top   : 15px;
            max-width     : 500px;

            h4 {
                margin-bottom : 45px;
            }
        }

        .footer-widget-recent-post, .footer-widget-contact {
            margin-bottom : 25px;
            padding-top   : 15px;

            h4 {
                margin-bottom : 35px;
            }
        }
    }

    .footer-foot-1, .footer-foot-2, .footer-foot-3, .footer-foot-4 {
        &.bgc-gray-base, &.bgc-gray-darkest, &.bgc-gray-darker {
            border-top-color : @gray-darker + #111111;
        }
    }

    @media @xs-max and (min-width : 550px) {
        .__block-wrapper-3 {
            width : 45%;
        }

        .__block-wrapper-4 {
            width : 55%;
        }
    }
}

.footer-preset-6 {
    .footer-body {
        padding-top    : 80px;
        padding-bottom : 45px;

        .footer-widget-about {
            margin-bottom : 40px;

            h4 {
                margin-bottom : 35px;
            }
        }

        .footer-widget-tag {
            margin-bottom : 40px;

            h4 {
                margin-bottom : 40px;
            }
        }

        .footer-widget-info {
            margin-bottom : 40px;

            h4 {
                margin-bottom : 35px;
            }
        }

        .footer-widget-newsletter {
            margin-bottom : 40px;

            h4 {
                margin-bottom : 35px;
            }
        }
    }

    .footer-foot-1, .footer-foot-2, .footer-foot-3, .footer-foot-4 {
        &.bgc-gray-base, &.bgc-gray-darkest, &.bgc-gray-darker {
            border-top-color : @gray-darker + #111111;
        }
    }
}

.footer-preset-7 {
    .footer-body {
        padding-top    : 100px;
        padding-bottom : 50px;

        .footer-widget-about {
            margin-bottom : 40px;

            p {
                margin-bottom : 45px;
            }
        }

        .footer-widget-social {
            margin-bottom : 35px;
        }

        .footer-widget-journal {
            margin-bottom : 40px;
        }

        .footer-widget-logo {
            margin-bottom : 30px;

            .megatron {
                margin-bottom : 10px;
                .logo {
                    position : relative;
                    z-index  : 10;
                    width    : 80px;
                    height   : 54px;
                }
            }

            hr {
                position : absolute;
                top      : 35px;
                left     : 0;
                margin   : 0;
                width    : 100%;
            }
        }

        .__block-wrapper-4 {
            padding-top : 10px;
        }

        &.bgc-light {
            border-top    : 1px solid @gray-border;
            border-bottom : 1px solid @gray-border;

            .footer-widget-logo {

                .megatron {
                    .logo {
                        background-color : @light-base;
                    }
                }
            }
        }

        &.bgc-gray-base {
            .footer-widget-logo {

                .megatron {
                    .logo {
                        background-color : @gray-base;
                    }
                }
            }
        }

        &.bgc-gray-darkest {
            .footer-widget-logo {

                .megatron {
                    .logo {
                        background-color : @gray-darkest;
                    }
                }
            }
        }

        &.bgc-gray-darker {
            .footer-widget-logo {

                .megatron {
                    .logo {
                        background-color : @gray-darker;
                    }
                }
            }
        }
    }
}

.footer-shop-1 {
    .footer-body {
        padding-top    : 80px;
        padding-bottom : 45px;

        .footer-widget-about {
            margin-bottom : 40px;

            h4 {
                margin-bottom : 35px;
            }
        }

        .footer-widget-shop-product {
            margin-bottom : 30px;
        }

        .footer-widget-newsletter {
            margin-bottom : 40px;

            h4 {
                margin-bottom : 35px;
            }
        }

        .footer-foot-1, .footer-foot-2, .footer-foot-3, .footer-foot-4 {
            &.bgc-gray-base, &.bgc-gray-darkest, &.bgc-gray-darker {
                border-top-color : @gray-darker + #111111;
            }
        }
    }

    @media @md-max and (min-width : @screen-md-min) {
        .footer-body {
            .footer-widget-shop-product {
                .block-shop-product-small-2 {
                    .__image {
                        margin-right : 15px;
                    }
                }
            }
        }
    }
}

.footer-shop-2 {
    .footer-body {
        padding-top    : 85px;
        padding-bottom : 45px;

        .footer-widget-shop-category {
            margin-bottom : 40px;
        }
    }

    .footer-foot-1, .footer-foot-2, .footer-foot-3, .footer-foot-4 {
        &.bgc-gray-base, &.bgc-gray-darkest, &.bgc-gray-darker {
            border-top-color : @gray-darker + #111111;
        }
    }

    @media @xs-max and (min-width : 600px) {
        .footer-body {
            .__block-wrapper-1, .__block-wrapper-2, .__block-wrapper-3, .__block-wrapper-4 {
                width : 50%;
            }
        }
    }
}

// Sticky Button

.sticky-button{
    position:fixed;
    right:0px;
    z-index:1450;
}

.purchase-button{
    top:200px;
    .sticky-button;
}

.referrals-button{
    top:400px;
    .sticky-button;
}