.block-testimonial {
    .__content {

        p {
            letter-spacing : 0.8px;
            margin-bottom  : 35px;
        }

        h6 {
            margin-bottom : 15px;
        }
    }
}

.block-testimonial-wrapper {
    .slick-arrow {
        top       : 20px;
        transform : translateY(0);
        opacity   : 1;
    }

    @media @sm-max {
        .slick-arrow {
            opacity : 0;
        }

        &:hover {
            .slick-arrow {
                opacity : 1;
            }
        }
    }
}

.block-testimonial-2 {
    .__content {

        p {
            letter-spacing : 0.8px;
            margin-bottom  : 25px;
        }

        i {
            font-size        : 20px;
            width            : 50px;
            height           : 50px;
            border-radius    : 50%;
            background-color : @gray-light;
            color            : @light-base;
            margin-bottom    : 25px;
            &:before {
                line-height : 50px;
                position    : relative;
                top         : 2px;
            }
        }

        h6 {
            margin-bottom : 15px;
        }
    }
}

.block-testimonial-2-wrapper {
    padding-bottom : 40px;
    .slick-dots {
        li {
            button {
                .square(8px);
                background-color : transparent;
                border           : 1px solid @gray-border;
            }

            &.slick-active button, button:hover {
                background-color : @gray-light;
                border-color     : @gray-light;
            }
        }
    }
}

.block-testimonial-3 {
    .__content {
        .__image {
            margin-left   : auto;
            margin-right  : auto;
            margin-bottom : 25px;
            overflow      : hidden;
            width         : 90px;
            height        : 90px;
            border-radius : 50%;

            img {
                width : 100%;
            }
        }

        p {
            letter-spacing : 0.05em;
            margin-bottom  : 25px;
        }

        h6 {
            margin-bottom : 15px;
        }
    }
}

.block-testimonial-3-wrapper {
    padding-bottom : 40px;
    .slick-dots {
        li {
            button {
                .square(8px);
                background-color : @gray-lighter;
                border: 0;
            }

            &.slick-active button, button:hover {
                background-color : @gray-light;
            }
        }
    }
}

.block-testimonial-4 {
    .__content {
        i {
            font-size     : 28px;
            color         : @gray-border;
            margin-bottom : 30px;
        }

        p {
            letter-spacing : 0.05em;
            margin-bottom  : 25px;
        }

        .__image {
            margin-left   : auto;
            margin-right  : auto;
            margin-bottom : 25px;
            overflow      : hidden;
            width         : 90px;
            height        : 90px;
            border-radius : 50%;

            img {
                width : 100%;
            }
        }

        h6 {
            margin-bottom : 15px;
        }
    }
}

.block-testimonial-5 {
    .__content {
        .__logo {
            margin-bottom : 35px;
        }

        .star-ratings {
            margin-bottom : 25px;
        }
        p {
            letter-spacing : 0.05em;
        }
    }
}

.block-testimonial-5-wrapper {
    padding-bottom : 36px;
    .slick-dots {
        font-size: 0;
        bottom: 28px;
        li {
            button {
                .square(8px);
                border: 0;
                background-color : fade(@light-base,30%);
            }

            &.slick-active button, button:hover {
                background-color : @light-base;
            }
        }
    }
}

.testimonial {
    background-size   : cover;
    background-repeat : no-repeat;

    .__button {
        .btn, .btn-border {
            max-width      : 180px;
            letter-spacing : 2px;
        }

        .btn {
            margin-right : 30px;
        }
    }

    &.style-1 {
        padding-top         : 110px;
        padding-bottom      : 420px;
        background-position : center bottom;

        .block-testimonial-wrapper {
            .slick-arrow {
                &:before {
                    text-shadow : none;
                }
                color : #AAAAAA;
            }
        }
    }

    &.style-2 {
        padding-top         : 130px;
        padding-bottom      : 400px;
        background-position : center top;

        .block-testimonial-wrapper {
            .slick-arrow {
            }
        }
    }

    &.style-3 {
        padding-top    : 140px;
        padding-bottom : 180px;

        .block-testimonial-wrapper {
            .slick-arrow {
            }
        }
    }

    @media @md {
        .block-testimonial-wrapper {
            max-width    : 770px;
            margin-left  : auto;
            margin-right : auto;
            width        : 100%;

            .slick-prev {
                left : -100px;
            }

            .slick-next {
                right : -100px;
            }
        }
    }

    @media @xs-max {
        .__button {
            .btn {
                margin-right  : 0;
                margin-bottom : 30px;
            }
        }
    }
}