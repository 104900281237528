.star-ratings {
    span {
        font-size: 20px;
        line-height: 1;
        margin: 0 2px;

        &.rated {
            color: #FFBF00;
        }

        &:not(.rated) {
            color: #AAAAAA;
        }
    }
}