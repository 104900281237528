.expendable-section {
    .expendable-section-header {
        height: 120px;
        border-top-width: 1px;
        border-top-style: solid;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        cursor: pointer;

        .__content {
            height: 100%;

            .__default, .__active {
                display: inline-block;
                height: 100%;
                position: relative;
                padding-left: 55px;

                .__icon {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 44px;

                    i {
                        display: block;
                        font-size: 44px;
                        width: 44px;
                        height: 44px;
                        line-height: 44px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
            .__active {
                display: none;
            }
        }

        &.header-active {
            .__content {
                .__default {
                    display: none;
                }

                .__active {
                    display: inline-block;
                }
            }
        }

        &.bgc-primary {
            border-color : @brand-primary;
        }

        &.bgc-light, &.bgc-gray-lighter {
            border-color : @gray-lighter;
        }

        &.bgc-gray-dark {
            border-color : @gray-dark;
        }
    }
}