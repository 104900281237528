.our-skill-left-image{
    position : relative;
    .__left-inner{
        background-size: cover;
        min-height: 400px;
        z-index : 10;

        @media @md {
            position : absolute;
            width :50%;
            height : 100%;
            top : 0;
            left : 0;
        }

        @media @sm-max {
            position : relative;
        }
    }
    .__right-inner{
        padding-top : 100px;
        padding-bottom : 95px;
        
        @media (min-width:(@screen-lg + 60px)) {
            transform: translateX(60px);
        }
        @media @sm and ( max-width: (@screen-lg-min + 60px)) {
            padding-left : 15px;
        }
    }
}

.block-feature {
    position: relative;

    .__icon {
        position: absolute;
        width: 100px;
        height: 100%;
        top: 0;
        left: 0;

        i {
            font-size: 45px;
            width: 45px;
            height: 45px;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .__content {
        padding-left : 100px;

        * {
            letter-spacing: 0.05em;
        }

        h3 {
            margin-bottom : 5px;
        }
        
        p {
            margin-bottom : 30px;
        }
    }
}