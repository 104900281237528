.grid-item {
    margin-bottom : 30px;
}
.remove-col-gutter .grid-item {
    margin-bottom : 0px;
}

.isotope-grid{
    transition: height 0.3s;
}

.portfolio-filter{
    border-bottom: 1px solid @gray-border;
    margin-bottom : 90px;
    text-align : center;
    display : flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .font-heading;

    .filter-button{
        border-bottom: 2px solid transparent;
        display : inline-block;
        padding-bottom : 15px;
        margin: 0 10px -2px;
        &.is-checked{
            border-bottom-color: @brand-primary;
        }
    }
}

.block-portfolio {
    text-align : center;

    &.overlay-container,.overlay-container{
        overflow: hidden;
    }

    .overlay {
        opacity: 0;
    }

    .__image {
        .easing-animation-long();
        width : 100%;
    }

    .__title {
        margin-bottom : 10px;
    }

    a:hover{
        color: inherit;
    }

    .__caption {
    }

    &:hover{
        .overlay{
            opacity : 1;
        }
        .__image{
            transform: scale(1.1);
        }
    }

    [class^='bgc-light-'], [class*='bgc-light-'] {
        &.overlay {
            border : 1px solid @gray-border;
        }
    }

    &.zoom {
        .__zoom{
            display     : block;
            font-size   : 22px;
            color       : @light-base;
            line-height : 1;
            opacity     : 0.6;
        }

        .overlay >.__zoom {
            position    : absolute;
            bottom      : 10px;
            right       : 10px;

            i{
                .icon();
            }

            &:hover {
                opacity : 1;
            }
        }

        .cell-middle >.__zoom {
            .center-block;
        }

        [class^='bgc-light-'], [class*='bgc-light-'] {
            .__zoom {
                color : @gray-dark;
            }
        }
    }

    &.group-function {
        .__group-function {
            padding-top : 20px;

            a {
                display       : inline-block;
                .square(45px);
                line-height   : 40px;
                border-radius : 50%;
                font-size     : 20px;
                border        : 1px solid @light-base;
                opacity       : 0.7;
                margin        : 0 5px;

                &:hover, &:active, &:focus {
                    color   : inherit;
                    opacity : 1;
                }

                i {
                    .icon();
                }
            }
        }
        [class^='bgc-light-'], [class*='bgc-light-'] {
            .__group-function a {
                border-color : @gray-dark;
            }
        }
    }

    &.middle-separator {
        .__title {
            position       : relative;
            padding-bottom : 14px;
            &:after {
                content       : '';
                position      : absolute;
                width         : 140px;
                left          : 50%;
                bottom        : 0;
                border-bottom : 1px solid fade(@light-base, 50%);
                transform     : translateX(-50%);
            }
        }

        [class^='bgc-light-'], [class*='bgc-light-'] {
            .__title:after {
                border-color : @gray-dark;
            }
        }
    }

    &.frame-bordered {
        .cell-vertical-wrapper {
            position     : relative;
            width        : ~"calc(100% - 30px)";
            height       : ~"calc(100% - 30px)";
            top          : 30px/2;
            left         : 30px/2;
            border-width : 1px;
            border-style : solid;
            border-color : @light-base;
        }

        [class^='bgc-light-'], [class*='bgc-light-'] {
            .cell-vertical-wrapper {
                border-color : @gray-dark;
            }

        }
    }

    &.title-foot {

        footer {
            .easing-animation();
            border  : 1px solid @gray-border;
            padding : 25px 15px;
        }

        &:hover{
            footer{
                border-color : @gray-darker;
                background-color : @gray-darker;
                color: @light-base;
            }
        }
    }
}

.group-portfolio{
    //- make gulters of wide layout are same width
    .container-fluid:not(.remove-col-gutter){
        margin-right : @grid-gutter-width / 2;
        margin-left : @grid-gutter-width / 2;
    }

    //- add padding when remove space
    .container.remove-col-gutter.remove-gutter{
        border-left:  @grid-gutter-width / 2 solid transparent;
        border-right:  @grid-gutter-width / 2 solid transparent;
    }

    @media @lg {
        .w20p{
            width : 20%;
        }
    }

}

// Portfolio Single
.portfolio-single-content {

}

.portfolio-single-info {
    .__item {
        margin-bottom: 20px;

        h6 {
            margin-bottom: 8px;
        }

        &.__tag {
            h6 {
                margin-bottom : 15px;
            }
        }
    }
}

// Portfolio Post
.portfolio-post-content {

}

.portfolio-post-info {
    .__item {
        margin-bottom: 20px;

        h6 {
            margin-bottom: 8px;
        }
    }
}

//Portfolio Box Slider
.portfolio-box-slider {
    .block-portfolio-box-slider {
        .__image {
            img {
                width: 100%;
            }
        }
    }
}

.portfolio-carousel-special{
    .slider{
        margin-left : -5px;
        margin-right : -5px;
    }
    .block-portfolio{
        border: 5px solid transparent
    }
}