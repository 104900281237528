.page-header{
    .clearfix();
    position : relative;
    overflow: hidden;
}

.common-header{
    padding-top : 60px;
    padding-bottom: 60px;
    height: 420px;

    &.no-padding {
        padding :0;
    }

    &.no-padding-top {
        padding-top: 0;
    }

    &.no-padding-bottom {
        padding-bottom: 0;
    }

    &.small{
        height: 320px;
    }

    &.large{
        height: 570px;
    }

    &.margin-top{
        margin-top: 120px;
    }
}

.header-2-col{
    padding-top : 60px;
    padding-bottom :60px - 25px;
    border-bottom: 1px solid @gray-lighter;
}

@keyframes LandingPageImageTransition {
    from {transform: translateX(-75%);}
    to {transform: translateX(-25%);}
}

@keyframes LandingPageBackgroundTransition {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.5);
    }
    100%{
        transform: scale(1);
    }
}

.landing-page-header{
    position : relative;
    width : 100%;
    overflow: hidden;

    h1.size-l{
        @media @xlg {
            font-size:70px;
            letter-spacing: 0.1em;
        }
        margin-bottom:10px;
    }

    .fz-3{
        @media @xlg {
            font-size : 25px;
        }
    }

    .layer-image,.layer-background{
        position : absolute;
        height: 100%;
        top : 0;
        left : 0;
    }

    .layer-background{
        width : 100%;
        background-size: cover;
        background-position: 50% 20%;
        z-index : -2;
        opacity: 1;
    }

    .layer-image{
        z-index : -1;
        opacity: 0.5;
        width : 8000px;
        background-repeat: repeat;
        background-size: 100vw ;

        // disable on Mac
        html:not(.chrome.MacIntel)  &{
            animation: LandingPageImageTransition linear 180s infinite;
        }
    }

    .layer-content{
        position : relative;
        background-image : url('../images/other/landing-bg.png');
        background-repeat : repeat;

        .cell-vertical-wrapper{
            padding-top : 120px;
            padding-bottom : 80px;
            height: 100vh;
        }
    }
    
    .megatron{
        width : 150px;

        @media @sm {
            width : 180px;
        }

        @media @md {
            width : 240px;
        }

        @media @lg {
            width : 290px;
        }

        @media @xlg {
            width : 370px;
        }
    }
    
    .go-to-body{
        .ab-bottom;
        height: 100px;
        width : 100%;
        a{
            display:none;
            @media @lg {
                font-size : 35px !important;
                opacity:0.7;
                display : inline-block;
            }
        }
        a:hover{
            transform: translateY(10px);
            color: inherit;
            opacity:1;
        }
    }
}


.simple-header{
    padding-top : 70px;
    padding-bottom : 70px;
    background-color : @gray-lightest;
    border-top : 1px solid @gray-border;
    border-bottom: 1px solid @gray-border;
}